import { Text, View } from 'react-native'
import React, { Component, useEffect, useState } from 'react'
import {getFundBasicInfo} from '../../utils/DatabaseHelper'
import {formatTimeStamp} from '../../utils/CommonHelper'
import useMyListContext from '../../hooks/useMyList';

const FundWatchList = () => {

    const { fundCodes } = useMyListContext()
    const [fundBasicInfoList, setFundBasicInfoList] = useState([])
    
    useEffect(() => {
        if(!fundCodes.length) return
        getFundBasicInfo(fundCodes).then((res) => {
            //console.log(res)
            setFundBasicInfoList(res.docs)
        })
    },[fundCodes]);

    return (
        <View className="flex-1 mx-2">
            {fundBasicInfoList[0]?.updateAt && (
                <Text className="text-white align-center text-center">更新时间: {fundBasicInfoList[0]?.updateAt}</Text>
            )}
            {fundCodes.length?(
            fundBasicInfoList?.map((item, index) => (
                <View 
                key={index}
                className={`flex-row bg-green-700 rounded-lg mx-2 p-2 mt-2 ${item.rate>0&&"bg-red-700"}`}>
                    <View className="flex-row">
                        <Text className="text-white">{item.fundName}</Text>
                        <Text className="text-white">[{item.code}]</Text>
                    </View>
                    <View className="flex-row ml-2">
                        <Text className="text-white">{item.net_value}</Text>
                        <Text className="text-white ml-2">{item.rate}%</Text>
                    </View>
                </View>
            ))
            ):(
                <Text className="text-white text-center">无列表</Text>
            )}
        </View>
        
    )
}

export default FundWatchList