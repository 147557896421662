import { View, Text } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ArrowCircleLeftIcon } from 'react-native-heroicons/outline'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import UserProfile from '../components/UserProfile'

const SettingsScreen = () => {
    const navigation = useNavigation()

    return (
    <SafeAreaView className="flex-1 bg-gray-900">
        <View className="m-4">
            <View className="flex-row justify-between">
                <TouchableOpacity
                    onPress={()=>{navigation.goBack()}}
                >
                    <ArrowCircleLeftIcon size={25} color="#fff" />
                </TouchableOpacity>
                <Text className="text-white text-xl font-bold">设置</Text>
            </View>
            <View>
                <UserProfile />
            </View>
        </View>
    </SafeAreaView>
    )
}

export default SettingsScreen