import { View, Text, ScrollView, ActivityIndicator, TouchableOpacity,Alert } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'

import DataReader from '../utils/DataReader'
import StockIndexTicker from '../components/StockIndexTicker'
import BottomNav from '../components/BottomNav'
import { SparklesIcon, CubeIcon, InboxIcon, CogIcon } from 'react-native-heroicons/solid'
import StockTabView from '../components/StockTabView'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import useMyListContext from '../hooks/useMyList'


const MINUTE_MS = 5000

const HomeScreen = () => {

    const isFocused = useIsFocused();

    const navigation = useNavigation()

    const { userSettings } = useMyListContext()

    const [stockIndexData, setStockIndexData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    

    const getStockIndexData =async () => {
        setIsLoading(true)
        const dataReader =new DataReader('stock-index-info',true)

        const data = await dataReader.get_request('/_all_docs?include_docs=true')
        
        let indexData = []
        const indexes = ["000001", "399001", "399006","000300","000905","399008"];
        data?.rows.map((item)=>{
            
            if (indexes.includes(item.doc.index_code)) {
                indexData.push(item.doc)
            }
        })
        
        setStockIndexData(indexData);
        setIsLoading(false)
    }

    useEffect(() => {
        isFocused && console.log('refreshed')
    },[isFocused])

    useEffect(() => {
        if(!isFocused) return;
        getStockIndexData();
        const interval = setInterval(() => {
            //console.log('Logs every minute');
            getStockIndexData();
        }, MINUTE_MS);
        
        return () => clearInterval(interval);
    },[]);

    if(userSettings===null) return null
    
    return (
        <SafeAreaView className="bg-gray-900 relative" style={{ flex:1, height:"auto" }}>
                <View>
                {/* Header */}
                <View className="flex-row justify-between items-center">
                    <View className="rounded-l-md p-5 text-center items-center flex-row">
                        <CubeIcon size={20} color="#fff"/>
                        <Text className="text-white text-xl font-bold ml-1">
                            iFinanceTools</Text>
                    </View>
                    <TouchableOpacity className="mr-4" onPress={()=>{navigation.navigate('Settings')}}>
                        <CogIcon size={25} color="#fff" />
                    </TouchableOpacity>
                </View>
                {/* Stock Index */}
                <View
                className="absolute top-[60px] right-10">
                {isLoading && (
                        <ActivityIndicator size="small" color="#fff"/>
                    )}
                </View>
                <ScrollView 
                horizontal={true}
                fadingEdgeLength={50}
                showsHorizontalScrollIndicator={false}
                className="mt-2 mx-3" 
                >
                    {
                        stockIndexData?.map((item) => (
                            <StockIndexTicker
                            key={item._id}
                            index_name={item.index_name} 
                            index_num={item.index_amount} 
                            rate={item.index_rate}
                            rate_num={item.index_rate_amount}
                            />
                        ))
                    }
                </ScrollView>
                </View>
                {/* Stock Card */}
                {isFocused && (
                    <View className="mt-1 flex-1">
                        <StockTabView/>
                    </View>
                )}
                <BottomNav className="absolute bottom-0" />
            
        </SafeAreaView>
    )
}

export default HomeScreen