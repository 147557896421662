import { View, Text, TouchableOpacity } from 'react-native'
import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { differenceInDays, format } from 'date-fns'


const StockCardItem = ({ id, strategyName, code, name, financialCompanyName, holdNum, costPerHold, type, createdAt, stockBasicInfoDict, bondBasicInfoDict, fundBasicInfoDict, tradeHistoryRateDict,tradeHistoryAllDict, stockShareHolderDict,stockCompanyProfile }) => {

  const navigation = useNavigation()

  const getStockCardBackGroundColor = (item) => {
    let res = false
    item?.map((i) => {
      //console.log(i)
      if(i > 0){
        res = true
      }
    })
    return res
  }

  const formatTradeHistoryRate = (item) => {
    let res = []
    item?.map((i) => (
      res.push(parseFloat(i).toFixed(2) + '% ')
    ))

    return res
  }

  const formatTimeStamp = (item) => {
    //console.log(item)
    return item&&format(new Date(item*1000), 'yyyy-MM-dd HH:mm:ss')
  }

  const formatDifferenceInDays = (timestamp) => {
    return differenceInDays(new Date(), new Date(timestamp))
  }

  return (
    <TouchableOpacity
    onPress={()=>{navigation.navigate('HoldListDetail',{
      id, strategyName, code, name, financialCompanyName, holdNum, costPerHold, type, createdAt, stockBasicInfoDict, bondBasicInfoDict,fundBasicInfoDict, tradeHistoryRateDict, tradeHistoryAllDict
    })}}
    className={`bg-green-200 rounded-lg m-5 p-4 ${getStockCardBackGroundColor(tradeHistoryRateDict[id])&&"bg-red-400"}`}>
      <View className="pb-10">
        <View className="flex-row absolute bg-gray-600 -bottom-4 -right-4 p-3 rounded-br-lg rounded-tl-xl">
          <Text className="text-white">[{strategyName}][{financialCompanyName}]</Text>
        </View>
        <View className="flex-row absolute bg-black -top-5 -left-4 p-3 rounded-r-lg">
            <Text className="font-extrabold text-white">[{type.toUpperCase()}][{code}]</Text>
            <Text className="font-bold ml-1 text-white">
              {bondBasicInfoDict[code]?.bond_name}
              {stockBasicInfoDict[code]?.stock_name}
              {fundBasicInfoDict[code]?.fundName}
            </Text>
            <Text className={`text-green-500 font-bold ml-1 ${stockBasicInfoDict[code]?.stock_rate>0 && "text-red-500"} ${bondBasicInfoDict[code]?.bond_rate>0 && "text-red-500"} ${fundBasicInfoDict[code]?.rate>0 && "text-red-500"}`}>
              {type=="stock"&&stockBasicInfoDict[code]?.price}
              {type=="bond"&&bondBasicInfoDict[code]?.bond_price}
              {type=="fund"&&fundBasicInfoDict[code]?.net_value}
            </Text>
            <Text className={`text-green-500 font-bold ml-1 ${stockBasicInfoDict[code]?.stock_rate>0 && "text-red-500"} ${bondBasicInfoDict[code]?.bond_rate>0 && "text-red-500"} ${fundBasicInfoDict[code]?.rate>0 && "text-red-500"}`}>
              {type=="stock"&&stockBasicInfoDict[code]?.stock_rate}
              {type=="bond"&&bondBasicInfoDict[code]?.bond_rate}
              {type=="fund"&&fundBasicInfoDict[code]?.rate}%
            </Text>
        </View>
        <View className="relative pt-6">
          <View className="border-dotted  mt-1 border-gray-400 ">
              {tradeHistoryRateDict[id]&&<Text className="font-bold">涨跌: {formatTradeHistoryRate(tradeHistoryRateDict[id])}</Text>}
              <Text >持仓: {holdNum} 资金: {(holdNum*costPerHold).toFixed(2)} 均价: {costPerHold}</Text>
          </View>
          {/* Disabled for performance optimistic */}
          {/* {type=='stock' && (
            <View className="border-dotted mt-1 border-gray-400">
            <Text>
              [{stockCompanyProfile[code]?.data["jbzl"]["qy"]}]
              [{stockCompanyProfile[code]?.data["jbzl"]["sshy"]}]</Text>
            <Text>实际控制人:{stockShareHolderDict[code]?.data["sjkzr"][0]["HOLDER_NAME"]}</Text>
            </View>
          )} */}
          <View>
              <Text>交易天数: {formatDifferenceInDays(createdAt)}D</Text>
              <Text>更新时间: 
              {type=="stock"&&formatTimeStamp(stockBasicInfoDict[code]?.update_at)}
              {type=="bond"&&formatTimeStamp(bondBasicInfoDict[code]?.updateAt)}
              {type=="fund"&&fundBasicInfoDict[code]?.updateAt}
              </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default StockCardItem