import { View, Text, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import BottomNav from '../components/BottomNav'
import StockNews from '../components/StockNews'
import DataReader from '../utils/DataReader'
import useMyListContext from '../hooks/useMyList'

const NewsScreen = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [myHoldList, setMyHoldList] = useState([])
    const [stockCodeList, setStockCodeList] = useState([])
    const [bondCodeList, setBondCodeList] = useState([])
    const [stockNews, setStockNews] = useState([])
    const [stockBasicInfoDict, setStockBasicInfoDict] = useState({})

    const { userSettings } = useMyListContext()

    const getMyList = async () => {
        const dataReader = new DataReader('finance-tools',true);
        const queryData = {
            "selector": {
                "_id": {"$eq": "myList:"+userSettings.email}
            },
            "fields": ["_id", "_rev", "watchList", "holdList", "notification"],
            "execution_stats": true
        }
        const myList = await dataReader.post_request('/_find',queryData);
        let [_myHoldList, _stockCodeList, _bondCodeList, _fundCodeList] = [[], [], [], []]
        myList.docs[0].holdList.map((item) => {
            if(item.enable){
                _myHoldList.push(item)
                switch (item.type) {
                    case 'stock':
                        _stockCodeList.push(item.code)
                        break;
                    case 'bond':
                        _bondCodeList.push(item.code)
                        break;
                    case 'fund':
                        _fundCodeList.push(item.code)
                        break;
                }
                
            }
            }
        )
        setMyHoldList(_myHoldList)
        setStockCodeList(_stockCodeList)
        setBondCodeList(_bondCodeList)
    }

    const getStockBasicInfo = async () => {
        setIsLoading(true)
        setStockBasicInfoDict({})
        const dataReader = new DataReader('stock-basic-info',true);
        const queryData = {
            "selector": {
                "stock_code": {"$in": stockCodeList}
            },
            "fields": [
                "_id",
                "_rev",
                "stock_code",
                "stock_secu_code",
                "stock_name",
                "price",
                "stock_rate",
                "stock_rate_amount",
                "stock_cjl",
                "stock_cje",
                "stock_zf",
                "high",
                "low",
                "open",
                "yesterday_close",
                "stock_hsl",
                "stock_syl",
                "stock_sjl",
                "stock_zsz",
                "stock_ltsz",
                "60days_rate",
                "year_start_rate",
                "5mins_rate",
                "update_at"
            ],
            "execution_stats": true
        }

        const _stockBasicInfo = await dataReader.post_request('/_find',queryData);
        let _dict = {}
        _stockBasicInfo.docs.map((item) => (
            _dict[item.stock_code] = item
        ))
        setStockBasicInfoDict(_dict)
        setIsLoading(false)
        //console.log(_stockBasicInfo.docs);
    }


    const getStockInformationBulletin = async () => {
        setIsLoading(true)
        const dataReader =new DataReader('stock-information-bulletin',true)

        const queryData = {
            "selector": {
                "_id": {"$in": stockCodeList.map((item)=>('stockInformationBulletin:'+item))}
            },
            "fields": [
                "_id",
                "_rev",
                "code",
                "data",
                "updateAt"
            ],
            "execution_stats": true
        }

        const data = await dataReader.post_request('/_find',queryData)
        
        //console.log(data)
        setStockNews(data.docs)
        setIsLoading(false)
    }

    useEffect(() => {
        getMyList()
    },[])

    useEffect(() => {
        if(!stockCodeList.length) return
        getStockInformationBulletin()
        getStockBasicInfo()
    },[stockCodeList])

    return (
        <SafeAreaView className="relative bg-gray-900 flex-1">
            <View className={`absolute align-center justify-center top-0 left-0 bottom-0 right-0 z-100`}>
                {isLoading && (
                        <ActivityIndicator size="large" color="#00ff00"/>
                    )}
            </View>
            <View className="h-[700px] pt-3 flex-1">
                <Text className="text-white text-xl pl-3 font-extrabold">新闻中心</Text>
                <StockNews 
                    stockNews={stockNews}
                    stockBasicInfoDict={stockBasicInfoDict}
                />
            </View>
            <BottomNav className="absolute bottom-0"/>
        </SafeAreaView>
    )
}

export default NewsScreen