import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import React, { useEffect, useState } from 'react'
import {formatTimeStamp} from '../../utils/CommonHelper'
import { getBondBasicInfo } from '../../utils/DatabaseHelper';
import useMyListContext from '../../hooks/useMyList';




const BondWatchList = () => {
    const { stockCodes, bondCodes, fundCodes } = useMyListContext()
    const [bondBasicInfoList, setBondBasicInfoList] = useState([])

    useEffect(() => {
        if(!bondCodes.length) return
        getBondBasicInfo(bondCodes).then((res) => {
            //console.log(res)
            setBondBasicInfoList(res.docs)
        })
        
    },[bondCodes])

    return (
        <View className="flex-1 mx-2">
            {bondBasicInfoList[0]?.updateAt&&(
                <Text className="text-white align-center text-center">更新时间: {formatTimeStamp(bondBasicInfoList[0]?.updateAt)}</Text>
            )}
            <ScrollView>
            {bondBasicInfoList.length ? (
                bondBasicInfoList?.map((item, index) => (
                    <TouchableOpacity key={index} className={`flex-row bg-green-700 rounded-lg mx-2 p-2 mt-2 ${item.bond_rate>0&&"bg-red-700"}`}>
                        <View className="flex-row">
                            <Text className="text-white">{item.bond_name}</Text>
                            <Text className="text-white">[{item.bond_code}]</Text>
                        </View>
                        <View className="flex-row ml-2">
                            <Text className="text-white">{item.bond_price}</Text>
                            <Text className="text-white ml-2">{item.bond_rate}%</Text>
                        </View>
                    </TouchableOpacity>
                ))
            ): null}
            </ScrollView>
        </View>
    )
}

export default BondWatchList