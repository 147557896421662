        import { View, Text, ActivityIndicator, TouchableOpacity } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { TrendingDownIcon, TrendingUpIcon } from 'react-native-heroicons/solid'
import { formatTimeStamp } from '../utils/CommonHelper'
import DataReader from '../utils/DataReader'
import BottomNav from '../components/BottomNav'

const MINUTE_MS = 60000

const GlobalMarketScreen = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [stockIndex, setStockIndex] = useState([]);

    const getGlobalStockIndex =async () => {
        setIsLoading(true)
        setStockIndex([])
        const dataReader = new DataReader('finance-tools',true);
        const queryData = {
            "selector": {
                "_id": {"$eq": "globalStockIndex"}
            },
            "fields": ["_id", "_rev", "data", "updateAt"],
            "execution_stats": true
        }

        const _globalStockIndex = await dataReader.post_request('/_find',queryData);
        //console.log(_globalStockIndex.docs[0])
        setStockIndex(_globalStockIndex.docs[0].data)
        setIsLoading(false)
    }

    useEffect(() => {
        getGlobalStockIndex()

        const interval = setInterval(() => {
            getGlobalStockIndex()
        }, MINUTE_MS);
        
        return () => clearInterval(interval);

    },[])

    //console.log(stockIndex)
    return (
        <SafeAreaView className="flex-1 bg-gray-900 relative">
            <View className="mx-4 mt-4 flex-1">
                <View className="flex-row items-end">
                <Text className="text-white font-extrabold text-xl ml-2">全球市场</Text>
                {stockIndex[0]?.update_at&&(<Text className="text-white text-xs pl-2">({formatTimeStamp(stockIndex[0]?.update_at)})</Text>)}
                </View>
                
                {stockIndex?.map((item) => (
                    <TouchableOpacity 
                    key={item.index_code}
                    className={`flex-row mt-2 bg-green-700 rounded-lg mx-2 ${item.index_rate>0&&"bg-red-700"}`}>
                        <View className="flex-row items-center">
                            <View className="pl-2">
                            {item.index_rate>0?(<TrendingUpIcon size={20} color="white" />):(<TrendingDownIcon size={20} color="white" />)}
                            </View>
                            <Text className="text-white p-2">{item.index_name}[{item.index_code}]</Text>
                        </View>
                        <View className="flex-row items-center ml-3 justify-evenly">
                            <Text className="text-white">{item.index_amount} {item?.currency}</Text>
                            <Text className="text-white ml-2">{item.index_rate}%</Text>
                        </View>
                    </TouchableOpacity>))
                }
            </View>
            <View className={`absolute align-center justify-center top-0 left-0 bottom-0 right-0 z-100`}>
                {isLoading && (
                        <ActivityIndicator size="large" color="#00ff00"/>
                    )}
            </View>
            <BottomNav className="absolute bottom-0"/>
        </SafeAreaView>
    )
}

export default GlobalMarketScreen