import { View, Text, StyleSheet, TextInput, TouchableOpacity, ImageBackground, Alert  } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Asset, useAssets } from 'expo-asset'
import { SparklesIcon, CubeIcon, InboxIcon, CogIcon } from 'react-native-heroicons/solid'
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { ALERT_TYPE, Root, Toast } from 'react-native-alert-notification';
import { useNavigation } from '@react-navigation/native'

import { getUserInfo } from '../utils/DatabaseHelper';
import useMyListContext from '../hooks/useMyList';

const LoginScreen = () => {
  const [assets, error] = useAssets([require('../assets/login-bg.jpg')]);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const navigation = useNavigation()

  const { isLoggedIn, setUserSettings, setIsLoggedIn  } = useMyListContext()

  const createTwoButtonAlert = () =>
  Alert.alert(
    "错误",
    "用户名或者密码错误",
    [
      {
        text: "Cancel",
        onPress: () => console.log("Cancel Pressed"),
        style: "cancel"
      },
      { text: "OK", onPress: () => console.log("OK Pressed") }
    ]
  );

  const checkIfValidUser = () => {
    if(!email || !password) return
    //TODO: check if user exsit at remote database
    getUserInfo(email, password).then((res) => {
      console.log(res)
      if(res.docs.length){
         //store login info at local storage
          storeData({
            "email": res.docs[0].email,
            "nickname": res.docs[0].nickname,
            "isLoggedIn": true
          }).then(()=>{
            //checkIfLoggedIn()
            setUserSettings({
              "email": res.docs[0].email,
              "nickname": res.docs[0].nickname,
              "isLoggedIn": true
            })
            setIsLoggedIn(true)
            navigation.navigate("Home")
          })
      }else{
        createTwoButtonAlert()
      }
    })
  }

  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem('userSettings', JSON.stringify(value))
    } catch (e) {
      // saving error
    }
  }

  useEffect(() => {
    if(!isLoggedIn) return
    navigation.navigate('Home')
  },[isLoggedIn])

  console.log(assets)
  return (
    //<Root>
    <SafeAreaView className="flex-1 bg-sky-800 relative">
      
     {assets && (
        <ImageBackground 
        className="flex-1 justify-center"
        // source={{uri: "https://file.pngbackground.com/uploads/preview/rider-photo-editing-cb-picsart-background-hd-download-new-11648971242tbblzbe37r.jpg"}}
        source={{uri: assets[0].uri}}
        resizeMode="cover">
        <View className="absolute items-center justify-center top-0 bottom-0 left-0 right-0">
          <View className="flex-row items-center">
          <CubeIcon size={100} color="#fff" className=""/>
          <Text className="text-white text-3xl font-extrabold shadow-lg shadow-black">iFinanceTools</Text>
          </View>
          
          <View className="flex-row items-center bg-slate-300 rounded-xl p-3 px-5 mt-5 w-[80%]">
            <Text className="text-black text-lg font-extrabold">邮箱: </Text>
            <TextInput
              className="bg-slate-100 p-3 focus:border-red-500 rounded-lg text-md ml-2 text-slate-400 w-[80%]"
              placeholder="请输入邮箱"
              keyboardType='email-address'
              value={email}
              onChangeText={(item)=>{setEmail(item)}}
            />
          </View>
          <View className="flex-row items-center bg-slate-300 rounded-xl p-3 px-5 mt-2 w-[80%]">
            <Text className="text-black text-lg font-extrabold">密码: </Text>
            <TextInput
              className="bg-slate-100 p-3 focus:border-red-500 rounded-lg text-md ml-2 text-slate-400 w-[80%]"
              placeholder="请输入密码"
              value={password}
              secureTextEntry={true}
              onChangeText={(item) => {setPassword(item)}}
            />
          </View>
          <View className="flex-row">
            <TouchableOpacity 
            onPress={() => {checkIfValidUser()}}
            className="bg-sky-500 p-3 mt-4 rounded-xl px-5 hover:bg-sky-200">
              <Text className="text-lg text-white">登录</Text>
            </TouchableOpacity>
            <TouchableOpacity 
            className="bg-blue-500 p-3 mt-4 ml-4 rounded-xl px-5 hover:bg-sky-200">
              <Text className="text-lg text-white">注册</Text>
            </TouchableOpacity>
          </View>
          
        </View>
         
        </ImageBackground>
     )} 
      
    </SafeAreaView>
    //</Root>
  )
}

const styles = StyleSheet.create({
  linearGradient: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 5
  },
  buttonText: {
    fontSize: 18,
    fontFamily: 'Gill Sans',
    textAlign: 'center',
    margin: 10,
    color: '#ffffff',
    backgroundColor: 'transparent',
  },
});

export default LoginScreen