import { Text, View, ScrollView, ActivityIndicator, useWindowDimensions } from 'react-native'
import React, { Component, useEffect, useState } from 'react'
import { getStockBasicInfo, getStockBasicInfoV2 } from '../../utils/DatabaseHelper'
import {formatTimeStamp} from '../../utils/CommonHelper'
import useMyListContext from '../../hooks/useMyList';

const MINUTE_MS = 60000

const StockWatchList = () => {

    const layout = useWindowDimensions();

    const { stockCodes } = useMyListContext()
    const [stockBasicInfoList, setStockBasicInfoList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getData = async () =>{
        setIsLoading(true)
        setStockBasicInfoList([])
        await getStockBasicInfoV2(stockCodes).then((res) => {
            //console.log(res)
            setStockBasicInfoList(res)
            setIsLoading(false)
        })
        // getStockBasicInfo(stockCodes).then((res) => {
        //     console.log(res.docs)
        //     setStockBasicInfoList(res.docs)
        //     setIsLoading(false)
        // })
    } 

    useEffect(() => {
        if(!stockCodes.length) return
        
        getData()
        const interval = setInterval(() => {
            console.log('Logs every minute');
            getData();
        }, MINUTE_MS);
        
        return () => clearInterval(interval);

    },[stockCodes])

    //console.log(layout)
    return (
        <View className="flex-1 mx-2 relative">
            <View className={`absolute align-center justify-center top-0 left-0 bottom-0 right-0 z-100`}>
                {isLoading && (
                        <ActivityIndicator size="large" color="#00ff00"/>
                    )}
            </View>
            <ScrollView>
                {stockBasicInfoList[0]?.update_at && (
                    <Text className="text-white align-center text-center">更新时间: {formatTimeStamp(stockBasicInfoList[0]?.update_at)}</Text>
                )}
                
            {stockCodes.length?(
                stockBasicInfoList?.map((item, index) => (
                    <View 
                    className={`flex-row bg-green-700 rounded-lg mx-2 p-2 mt-2 ${item.stock_rate>0&&"bg-red-700"}`}
                    key={index}>
                        <View className="flex-row">
                        <Text className="text-white">{item.stock_name}</Text>
                        <Text className="text-white">[{item.stock_code}]</Text>
                        </View>
                        <View className="flex-row ml-2">
                        <Text className="text-white">{item.price}</Text>
                        <Text className="text-white ml-2">{item.stock_rate}%</Text>
                        <Text className="text-white ml-2">2M:{item['60days_rate']}%</Text>
                        <Text className="text-white ml-2">Y:0{item.year_start_rate}%</Text>
                        </View>
                    </View>
                ))
            ):(
                <Text className="text-white text-center">无列表</Text>
            )}
            </ScrollView>
        </View>
        
    )
}

export default StockWatchList