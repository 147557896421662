import { View, Text, useWindowDimensions } from 'react-native'
import React from 'react'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';

import StockCard from './StockCard';
import StockWatchListTabView from './StockWatchListTabView'

const FirstRoute = () => (
    <StockCard />
);

const SecondRoute = () => (
    <StockWatchListTabView />
);

const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
});

const renderTabBar = props => (
    <TabBar
        {...props}
        tabStyle={{ width: 'auto' }}
        indicatorStyle={{ backgroundColor: 'lightblue' }}
        style={{ backgroundColor: 'transparent', marginLeft: 10}}
        renderLabel={({ route, focused, color }) => (
            <View className={`rounded-md ${focused&&"bg-sky-600"}`}>
                <Text className="text-md text-white p-2">
                    {route.title}
                </Text>
            </View> 
        )}
    />
);

const StockTabView = () => {
    const layout = useWindowDimensions();

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'first', title: '持仓列表' },
        { key: 'second', title: '观察列表' },
    ]);

    
    return (
        <TabView
        lazy={({ route }) => route.key === 'second'}
        renderTabBar={renderTabBar}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        />
    )
}

export default StockTabView