import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { TailwindProvider } from 'tailwindcss-react-native';

import HomeScreen from './screens/HomeScreen';
import NewsScreen from './screens/NewsScreen'
import SettingsScreen from './screens/SettingsScreen'
import LoginScreen from './screens/LoginScreen'
import HoldListDetailScreen from './screens/HoldListDetailScreen';
import GlobalMarketScreen from './screens/GlobalMarketScreen';
import StrategyScreen from './screens/StrategyScreen';

import { MyListProvider } from './hooks/useMyList';

const Stack = createNativeStackNavigator();

export default function App() {

  return (
    <NavigationContainer>
      <MyListProvider>
          <TailwindProvider>
            <Stack.Navigator
              screenOptions={{ headerShown: false }}
              initialRouteName="Home"
              defaultScreenOptions={{headerMode: 'none'}}
              headerMode='none'
            >
              <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }}/>
              <Stack.Screen 
              name="HoldListDetail" 
              component={HoldListDetailScreen} 
              options={{ 
                headerShown: false,
                animation: "fade_from_bottom",
                presentation: "transparentModal"
              }}/>
              <Stack.Screen 
              name="Strategy" 
              component={StrategyScreen} 
              options={{ 
                headerShown: false,
                animation: "slide_from_right",
              }}/>
              <Stack.Screen 
              name="GlobalMarket" 
              component={GlobalMarketScreen} 
              options={{ 
                headerShown: false,
                animation: "slide_from_right",
              }}/>
              <Stack.Screen 
              name="News" 
              component={NewsScreen} 
              options={{ 
                headerShown: false,
                animation: "slide_from_right",
              }}/>
              <Stack.Screen 
              name="Settings" 
              component={SettingsScreen} 
              options={{ 
                headerShown: false
              }}/>
              <Stack.Screen 
              name="Login" 
              component={LoginScreen} 
              options={{ 
                headerShown: true,
                headerTransparent: true,
                headerStyle: {
                  backgroundColor: 'transparent'
              }
              }}/>
            </Stack.Navigator>
          </TailwindProvider>
          </MyListProvider>
      </NavigationContainer>
  );
}
