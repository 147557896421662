import { View, Text, Linking, ScrollView } from 'react-native'
import React from 'react'
import { formatTimeStamp } from '../utils/CommonHelper'

const StockNews = ({stockNews, stockBasicInfoDict}) => {
    const showLinkType = (title) => {
        let _type = 'text-black'
        const _blackListedWords = ['重组','警示函','立案','业绩','卖资产','踩雷','负债','利润','冻结','减持']
        const _blackListedWordsST = ['解禁','质押','退市']
        _blackListedWords.forEach((e) => {
            if(title.includes(e)){
                _type = 'text-blue-500'
            }
        })
        _blackListedWordsST.forEach((e) => {
            if(title.includes(e)){
                _type = 'text-red-500'
            }
        })
        return _type
    }

    return (
        <ScrollView 
        horizontal={true}
        // showsHorizontalScrollIndicator={false}
        className="flex-1 relative">
            {stockNews&&(
                stockNews.map((item)=>(
            <View 
            key={item.code}
            className="bg-gray-100 rounded-lg p-3 m-3 w-[300px]">
                <View className="absolute bg-black p-3 -top-2 rounded-md flex-row items-center">
                    <Text className="text-xl text-white">{stockBasicInfoDict[item.code]?.stock_name}</Text>
                    <View className="pl-2">
                        <Text className={`text-xs text-green-500 ${stockBasicInfoDict[item.code]?.stock_rate>0&&("text-red-500")}`}>
                            {stockBasicInfoDict[item.code]?.price}
                        </Text>
                        <Text className={`text-xs text-green-500 ${stockBasicInfoDict[item.code]?.stock_rate>0&&("text-red-500")}`}>
                            {stockBasicInfoDict[item.code]?.stock_rate}%
                        </Text>
                    </View>
                    
                </View>
                <View className="text-md font-bold text-green-500 pt-10">
                    <Text className="font-bold">公告({item.data['gsgg'][0].display_time})</Text>
                    <View className="pl-3">
                        {item.data['gsgg'].map((gg, index) => (
                            <Text 
                            key={index}
                            onPress={() => Linking.openURL(`https://data.eastmoney.com/notices/detail/${item.code}/${gg['art_code']}.html`)}
                            className={`text-xs focus:text-red-500 ${showLinkType(gg.title)}`}
                            >
                                {gg.title}
                            </Text>
                        ))}
                    </View>
                </View>
                <View className="text-md font-bold text-green-500 pt-2">
                    <Text className="font-bold">资讯({formatTimeStamp(item.data['gszx']['data']['items'][0]['showDateTime']/1000)})</Text>
                    <View className="pl-3">
                        {item.data['gszx']['data']['items'].map((zx, index) => (
                            <Text
                            key={index}
                            onPress={() => Linking.openURL(`${zx['uniqueUrl']}`)}
                            className={`text-xs focus:text-red-500 ${showLinkType(zx.title)}`}
                            >
                                {zx.title}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>
                ))
            )}
            
        </ScrollView>
    )
}

export default StockNews