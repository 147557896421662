import { format } from "date-fns";


const formatTradeHistoryRate = (item) => {
    let res = []
    item?.map((i) => (
      res.push(parseFloat(i).toFixed(2) + '% ')
    ))

    return res
  }

const formatTimeStamp = (item) => {
    //console.log(item)
    return item&&format(new Date(item*1000), 'yyyy-MM-dd HH:mm:ss')
}


export {
    formatTimeStamp,
    formatTradeHistoryRate
}