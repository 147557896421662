import { View, Text } from 'react-native'
import React from 'react'
import { TouchableOpacity } from 'react-native'

const StockIndexTicker = ({index_name, index_num, rate_num, rate}) => {
    return (
        <TouchableOpacity className={`bg-green-200 p-1 m-1 w-28 h-[70px] shadow-md rounded-lg items-center ${rate>0 && "bg-red-200"}`}>
            
                <Text className="text-lg font-bold">{index_name}</Text>
                <Text className={`text-green-800 font-bold text-md ${rate > 0 && "text-red-600"}`}>{index_num}</Text>
                <View className="flex-row justify-between">
                    <Text className={`text-green-900 ${rate > 0 && "text-red-600"}`}>{rate_num}</Text>
                    <Text className={`text-green-900 mx-1 ${rate > 0 && "text-red-600"}`}>{rate}%</Text>
                </View>
            
        </TouchableOpacity>
    )
}

export default StockIndexTicker