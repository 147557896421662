import { View, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';

import useMyListContext from '../hooks/useMyList'
import { useNavigation } from '@react-navigation/native'

const UserProfile = () => {

    const navigation = useNavigation()

    const {userSettings} = useMyListContext()

    const logOut = async () => {
        try {
          await AsyncStorage.removeItem('userSettings').then(() => (
            navigation.navigate('Login')
          ))
        } catch(e) {
          // remove error
        }
        
    }

    return (
    <View className="bg-slate-200 flex-1 p-3 rounded-lg mt-4 items-center justify-center">
        <View className="flex-row">
            <Text className="font-bold text-md">版本:</Text>
            <Text className="font-bold text-md ml-2">v 1.1.0</Text>
        </View>
        <View className="flex-row">
            <Text className="font-bold text-md">当前用户:</Text>
            <Text className="font-bold text-md ml-2">{userSettings?.email}</Text>
        </View>
        <TouchableOpacity 
        onPress={logOut}
        className="bg-red-500 p-2 w-28 rounded-md mt-2 text-center">
            <Text className="text-white">退出登录</Text>
        </TouchableOpacity>
    </View>
    )
}

export default UserProfile