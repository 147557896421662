import { createContext, useContext, useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import React from 'react'
import DataReader from '../utils/DataReader'
import { useNavigation } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage';

const MyListContext = createContext({})




export const MyListProvider = ({children}) => {

    const navigation = useNavigation()

    const [isAppLoading, setIsAppLoading] = useState(true)
    const [myList, setMyList] = useState({})
    const [stockCodes, setStockCodes] = useState([])
    const [bondCodes, setBondCodes] = useState([])
    const [fundCodes, setFundCodes] = useState([])
    const [isLoggedIn, setIsLoggedIn]= useState(false)
    const [userSettings, setUserSettings] = useState(null)

    const checkIfLoggedIn = async () => {
        try {
          let value = await AsyncStorage.getItem('userSettings')
          if(value) {
            value = JSON.parse(value)
            //console.log(value.email)
            setUserSettings(value)
            setIsLoggedIn(value.isLoggedIn)
          }else{
            navigation.navigate('Login')
          }
        } catch(e) {
          navigation.navigate('Login')
        }
      }

    const requestUserSession = async() => {
        console.log(2222)
        const dataReader = new DataReader(null);
        const _res =  await dataReader.request_user_session()
        console.log(_res)
    }

    const getMyList = async () => {
        const dataReader = new DataReader('finance-tools',true);
        const queryData = {
            "selector": {
                "_id": {"$eq": "myList:" + userSettings.email}
            },
            "fields": ["_id", "_rev", "watchList", "holdList", "notification"],
            "execution_stats": true
        }
        const _myList = await dataReader.post_request('/_find',queryData);
        //console.log(_myList.docs[0])
        let [_stockCodes, _bondCodes, _fundCodes] = [[],[],[]]
        _myList.docs[0]?.watchList.map((item) => {
            switch (item.type) {
                case 'stock':
                    _stockCodes.push(item.code)
                    break;
                case 'bond':
                    _bondCodes.push(item.code)
                    break;
                case 'fund':
                    _fundCodes.push(item.code)
                    break;
            }
        })
        setStockCodes(_stockCodes)
        setBondCodes(_bondCodes)
        setFundCodes(_fundCodes)

        setMyList(_myList.docs[0])
        setIsAppLoading(false)
    }
    
    useEffect(() => {
        //requestUserSession()
        checkIfLoggedIn()
    },[])

    
    useEffect(() => {
        if(!userSettings) return
        getMyList()
    },[userSettings])

    return (
        <MyListContext.Provider value={{
            isAppLoading,
            isLoggedIn,
            userSettings,
            myList,
            bondCodes,
            stockCodes,
            fundCodes,
            setUserSettings,
            setIsLoggedIn
        }}>
            {children}
        </MyListContext.Provider>
    )
}

export default function useMyListContext() {
    return useContext(MyListContext)
}
