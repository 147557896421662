import axios from "axios";
//import { API_USER, API_PASS, API_URL } from "@env"
import { Buffer } from 'buffer'
import qs from 'qs'

const API_URL='http://38.47.238.19:5984/'
//const API_URL='http://localhost:5984/'
const API_USER='ifinancetoolsapp'
const API_PASS='Zj#8263079'
let DB_NAME = null

class DataReader {

    constructor(db_name, requireAuthorition = false) {

        if(requireAuthorition){
            const token = Buffer.from(`${API_USER}:${API_PASS}`, 'utf8').toString('base64')

            this.headers ={
                headers: {
                    'Authorization': `Basic ${token}`,
                    //'Cookie': 'AuthSession=YWRtaW46NjMwMEM4QjU6byduM34pRA5hLsRa_lNG-caMYIE',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        }else{
            this.headers = {
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
            }
        }
        DB_NAME = db_name
    }


    async request_user_session() {

        const data= {"name": API_USER, "password": API_PASS}
        console.log(1111)
        return axios.post(API_URL + '_session', data, this.headers).then(
            (response) => { 
                return response.data
            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }

    async get_request(url, params={}){ 
        return await axios.get(
            API_URL + DB_NAME + url,
            {
                headers: this.headers['headers'],
                params: params,
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(
            (response) => { 
                return response.data
            }
        ).catch(
            error => {
                //console.error(error)
            }
        )
    }

    async post_request(url, data){
        console.log(API_URL + DB_NAME + url)
        return axios.post(API_URL + DB_NAME + url, data, this.headers).then(
            (response) => { 
                return response.data
            }
        ).catch(
            error => {
                //console.error(error)
            }
        )
    }

    async put_request(url, data){
        return axios.put(API_URL + DB_NAME + url, data, this.headers).then(
            (response) => { 
                return response.data
            }
        ).catch(
            error => {
                //console.error(error)
            }
        )
    }

}

export default DataReader