import { View, Text, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { FAB, Portal, Provider } from 'react-native-paper'
import DataReader from '../utils/DataReader'
import useMyListContext from '../hooks/useMyList'

import BottomNav from '../components/BottomNav'

const StrategyScreen = () => {

    const [state, setState] = React.useState({ open: false });
    const onStateChange = ({ open }) => setState({ open });
    const { open } = state;

    const {userSettings} = useMyListContext()
    const [isLoading, setIsLoading] = useState(true)
    const [tradeStrategyList, setTradeStrategyList] = useState({})

    const getTradeStrategyList = async () => {
        const dataReader = new DataReader('finance-tools',true);
        await dataReader.get_request('/tradeStrategyList').then((res) => (
            //console.log(res)
            setTradeStrategyList(res)
        )).finally(() => (
            setIsLoading(false)
        ))
    }

    useEffect(() => {
        getTradeStrategyList()
    },[])

    return (
        <SafeAreaView className="bg-slate-900 flex-1 relative">
            <Provider>
            <Portal>
                <FAB.Group
                className="bottom-20 z-[999] absolute"
                open={open}
                icon={open ? 'calendar-today' : 'plus'}
                actions={[
                    { icon: 'plus', onPress: () => console.log('Pressed add') },
                    {
                    icon: 'star',
                    label: 'Star',
                    onPress: () => console.log('Pressed star'),
                    },
                    {
                    icon: 'email',
                    label: 'Email',
                    onPress: () => console.log('Pressed email'),
                    },
                    {
                    icon: 'bell',
                    label: 'Remind',
                    onPress: () => console.log('Pressed notifications'),
                    },
                ]}
                onStateChange={onStateChange}
                onPress={() => {
                    if (open) {
                    // do something if the speed dial is open
                    }
                }}
                />
            
            <View className="absolute align-center justify-center top-0 left-0 bottom-0 right-0 z-100">
            {isLoading && (
                    <ActivityIndicator size="small" color="#00ff00"/>
                )}
            </View>
            <ScrollView className="flex-1 mx-2 mt-[20px]">
                <Text className="text-white text-lg font-bol ml-4">策略中心</Text>
                {tradeStrategyList.strategies?.map((item, index) => (
                    <TouchableOpacity
                    className="bg-slate-500 p-2 rounded-lg m-2 my-4"
                    key={index}
                    >
                        <Text className="text-white text-lg font-bold shadow-xl">{item.name}</Text>
                        <Text className="text-white mt-2">详细: {item.detail}</Text>
                        <Text className="text-white mt-2 bg-slate-600 p-1">公式: {item.formula}</Text>
                        <View className="flex-row mt-2">
                        {item.applyType?.map((_applyType, i) => (
                            <Text 
                            key={i}
                            className="text-white bg-blue-500 p-1 w-14 mr-2 text-center rounded-md">{_applyType}</Text>
                        ))}
                        </View>
                    </TouchableOpacity>
                ))}
            </ScrollView>
            <BottomNav className="absolute bottom-0"/>
            </Portal>
            </Provider>
        </SafeAreaView>
    )
}

export default StrategyScreen