import { View, Text, useWindowDimensions } from 'react-native'
import React from 'react'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';

import BondWatchList from './subComponents/BondWatchList';
import StockWatchList from './subComponents/StockWatchList'
import FundWatchList from './subComponents/FundWatchList'

const FirstRoute = () => (
    <StockWatchList />
);

const SecondRoute = () => (
    <BondWatchList />
);

const ThirdRoute = () => (
    <FundWatchList />
);

const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
    third: ThirdRoute,
});

const renderTabBar = props => (
    <TabBar
        {...props}
        tabStyle={{ width: 'auto' }}
        indicatorStyle={{ backgroundColor: 'lightblue' }}
        style={{ backgroundColor: 'transparent', marginLeft: 10}}
        renderLabel={({ route, focused, color }) => (
            <View className={`rounded-md ${focused&&"bg-sky-500"}`}>
                <Text className="text-lg text-white p-2">
                    {route.title}
                </Text>
            </View> 
        )}
    />
);

const StockWatchListTabView = () => {

    const layout = useWindowDimensions();

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'first', title: '股票' },
        { key: 'second', title: '债券' },
        { key: 'third', title: '基金' },
    ]);

    return (
        <TabView
        renderTabBar={renderTabBar}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
        />
    )
}

export default StockWatchListTabView