import { View, Text, ScrollView } from 'react-native'
import React from 'react'
import { HomeIcon, HeartIcon, NewspaperIcon, ChartBarIcon, ChartSquareBarIcon,CurrencyDollarIcon } from 'react-native-heroicons/outline'
import NavButton from './NavButton'
import { useNavigation } from '@react-navigation/native'

const BottomNav = () => {
    const navigation = useNavigation();

    return (
        <View className="flex-row justify-evenly pt-1 border-t-2 border-gray-800">
            <NavButton 
            navTo="Home"
            Icon={HeartIcon} 
            btnTxt="持仓列表" />
            <NavButton Icon={ChartBarIcon} btnTxt="行情中心" />
            <NavButton
            navTo="Strategy"
            Icon={ChartSquareBarIcon} 
            btnTxt="策略池" />
            <NavButton navTo="GlobalMarket" Icon={CurrencyDollarIcon} btnTxt="全球市场" />
            <NavButton 
            navTo="News"
            Icon={NewspaperIcon} 
            btnTxt="新闻" />
        </View>
        
    )
}

export default BottomNav