import { View, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'

const NavButton = ({Icon, btnTxt, navTo}) => {
  const navigation = useNavigation();
  
  return (
    <TouchableOpacity 
    onPress={() => {navigation.navigate(navTo)}}
    className="
    items-center justify-center align-middle bg-gray-900 px-5 py-3 rounded-xl border-r-2 border-gray-500">
        <Icon size={20} style={{color:"white"}} />
        <Text className="text-white text-md">{btnTxt}</Text>
    </TouchableOpacity>
  )
}

export default NavButton