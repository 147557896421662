import { View, Text, TouchableOpacity } from 'react-native'
import React from 'react'
import { formatTradeHistoryRate } from '../utils/CommonHelper'

const HoldListHeaderButtons = ({
  myHoldListData, 
  tradeHistoryRateDict, 
  stockBasicInfoDict, 
  bondBasicInfoDict,
  fundBasicInfoDict}) => {

    //console.log(tradeHistoryRateDict);

    const getStockCardBackGroundColor = (item) => {
        let res = false
        item?.map((i) => {
          //console.log(i)
          if(i > 0){
            res = true
          }
        })
        return res
      }

    return (
        <View className="flex-row flex-wrap mx-4">
       {myHoldListData.length?( myHoldListData?.map((item, index) => (
            <TouchableOpacity
            key={index}
            className={`bg-green-500 w-[80px] ml-3 text-center items-center justify-center p-1 rounded-md m-1 ${getStockCardBackGroundColor(tradeHistoryRateDict[item.id])&&"bg-red-400"}`}>
                <View className="items-center">
                  {item.type=='stock'&& <Text className="text-white">{stockBasicInfoDict[item.code]?.stock_name}</Text>}
                  {item.type=='bond'&& <Text className="text-white">{bondBasicInfoDict[item.code]?.bond_name}</Text>}
                  {item.type=='fund'&& <Text className="text-white">{fundBasicInfoDict[item.code]?.fundName}</Text>}
                  <Text className='text-white'>{formatTradeHistoryRate(tradeHistoryRateDict[item.id])}</Text>
                </View>
                <View className="bg-slate-500 w-full rounded px-1 items-center">
                  {item.type=='stock'&& <Text className="text-white">{stockBasicInfoDict[item.code]?.price}</Text>}
                  {item.type=='bond'&& <Text className="text-white">{bondBasicInfoDict[item.code]?.bond_price}</Text>}
                  {item.type=='fund'&& <Text className="text-white">{fundBasicInfoDict[item.code]?.net_value}</Text>}
                </View>
                
            </TouchableOpacity>
        ))
       ):null}
        </View>
    )
}

export default HoldListHeaderButtons