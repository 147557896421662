import { View, Text, TouchableOpacity, FlatList, ActivityIndicator, Button } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import React, { useEffect, useState } from 'react'
import { differenceInDays } from 'date-fns'
//import { ALERT_TYPE, Dialog, Root, Toast } from 'react-native-alert-notification';

import StockCardItem from './StockCardItem'
import HoldListHeaderButtons from './HoldListHeaderButtons'
import DataReader from '../utils/DataReader'

import useMyListContext from '../hooks/useMyList'

const MINUTE_MS = 60000

const StockCard = () => {

    const {userSettings} = useMyListContext()

    
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingTradeHistory, setIsLoadingTradeHistory] = useState(false)
    const [myListData, setMyListData] = useState({});
    
    const [stockBasicInfo, setStockBasicInfo] = useState([]);
    const [stockCodeList, setStockCodeList] = useState([]);
    const [bondCodeList, setBondCodeList] = useState([]);
    const [fundCodeList, setFundCodeList] = useState([]);

    const [stockBasicInfoDict, setStockBasicInfoDict] = useState({});
    const [stockShareHolderDict, setStockShareHolderDict] = useState({});
    const [stockCompanyProfile, setStockCompanyProfile] = useState({});

    const [bondBasicInfoDict, setBondBasicInfoDict] = useState({});
    const [fundBasicInfoDict, setFundBasicInfoDict] = useState({})

    const [myHoldListData, setMyHoldListData] = useState([]);

    const [tradeHistoryEnabledDict, setTradeHistoryEnabledDict] = useState({});
    const [tradeHistoryAllDict, setTradeHistoryAllDict] = useState({});
    const [tradeHistoryRateDict, setTradeHistoryRateDict] = useState({})

    const getMyListData = async () => {
        const dataReader = new DataReader('finance-tools',true);
        const queryData = {
            "selector": {
                "_id": {"$eq": "myList:"+userSettings.email}
            },
            "fields": ["_id", "_rev", "watchList", "holdList", "notification"],
            "execution_stats": true
        }
        const myList = await dataReader.post_request('/_find',queryData);
        let [myHoldList, _stockCodeList, _bondCodeList, _fundCodeList] = [[], [], [], []]
        myList.docs[0].holdList.map((item) => {
            if(item.enable){
                myHoldList.push(item)
                switch (item.type) {
                    case 'stock':
                        _stockCodeList.push(item.code)
                        break;
                    case 'bond':
                        _bondCodeList.push(item.code)
                        break;
                    case 'fund':
                        _fundCodeList.push(item.code)
                        break;
                }
                
            }
            }
        )
        setMyHoldListData(myHoldList)
        setStockCodeList(_stockCodeList)
        setBondCodeList(_bondCodeList)
        setFundCodeList(_fundCodeList)
        setMyListData(myList.docs[0])
    }

    const getStockInfoData = async () => {
        setStockBasicInfoDict({})
        const dataReader = new DataReader('stock-basic-info',true);

        const promisesArray = [];

        stockCodeList.forEach(_stockCode => {
            promisesArray.push(new Promise((resolve, reject) => {
               const res = dataReader.get_request('/stockBasicInfo%3A' + _stockCode);
               resolve(res);
            }));
        });

        Promise.all(promisesArray).then((_dataList) => {
            //console.log(values);
            let _dict = {}
            _dataList.forEach(_data => {
                _dict[_data._id.split(":")[1]] = _data
            });
            setStockBasicInfoDict(_dict);
            //console.log(_dict);
          });
        // const queryData = {
        //     "selector": {
        //         "stock_code": {"$in": stockCodeList}
        //     },
        //     "fields": [
        //         "_id",
        //         "_rev",
        //         "stock_code",
        //         "stock_secu_code",
        //         "stock_name",
        //         "price",
        //         "stock_rate",
        //         "stock_rate_amount",
        //         "stock_cjl",
        //         "stock_cje",
        //         "stock_zf",
        //         "high",
        //         "low",
        //         "open",
        //         "yesterday_close",
        //         "stock_hsl",
        //         "stock_syl",
        //         "stock_sjl",
        //         "stock_zsz",
        //         "stock_ltsz",
        //         "60days_rate",
        //         "year_start_rate",
        //         "5mins_rate",
        //         "update_at"
        //     ],
        //     "execution_stats": true
        // }

        // const _stockBasicInfo = await dataReader.post_request('/_find',queryData).then((res)=>{
        //     let _dict = {}
        //     setStockBasicInfo(res.docs);
        //     res.docs.map((item) => (
        //         _dict[item.stock_code] = item
        //     ))
        //     setStockBasicInfoDict(_dict)
        // });
        
        //console.log(_stockBasicInfo.docs);
    }

    const getBondBasicInfo = async () => {
        const dataReader = new DataReader('bond-basic-info',true);

        const promisesArray = [];
        bondCodeList.forEach(_bondCode => {
            promisesArray.push(new Promise((resolve, reject) => {
               const res = dataReader.get_request('/bondBasicInfo%3A' + _bondCode);
               resolve(res);
            }));
        });
        Promise.all(promisesArray).then((_dataList) => {
            let _dict = {}
            _dataList.forEach(_data => {
                _dict[_data._id.split(":")[1]] = _data
            });
            setBondBasicInfoDict(_dict)
        });
       
    }

    const getFundBasicInfo = async () => {
        const dataReader = new DataReader('fund-basic-info',true);

        const promisesArray = [];
        fundCodeList.forEach(_fundCode => {
            promisesArray.push(new Promise((resolve, reject) => {
               const res = dataReader.get_request('/fundBasicInfo%3A' + _fundCode);
               resolve(res);
            }));
        });
        Promise.all(promisesArray).then((_dataList) => {
            let _dict = {}
            _dataList.forEach(_data => {
                _dict[_data._id.split(":")[1]] = _data
            });
            setFundBasicInfoDict(_dict)
        });
    }

    const getTradeHistoryData = async () => {
        setIsLoadingTradeHistory(true)
        const dataReader = new DataReader('finance-tools',true);
        const queryData = {
            "selector": {
                "_id": {"$eq": "tradeHistory:" + userSettings.email}
            },
            "fields": ["_id", "_rev", "data"],
            "execution_stats": true
        }

        const tradeHistory = await dataReader.post_request('/_find',queryData);
        //console.log(tradeHistory.docs[0])
        let [_tradeHistory,_tradeHistoryAll] = [{},{}]
        tradeHistory.docs[0].data.map((item) => {
            _tradeHistoryAll[item.refMyListId] = []
            if(item.enable && item.tradeType == 'buy'){
                _tradeHistory[item.refMyListId] = []
            }
        })
        
        tradeHistory.docs[0].data.map((item) => {
            _tradeHistoryAll[item.refMyListId].push(item)
            if(item.enable && item.tradeType == 'buy'){
                _tradeHistory[item.refMyListId].push(item)
            }
        })

        //console.log(_tradeHistory)
        setTradeHistoryEnabledDict(_tradeHistory)
        setTradeHistoryAllDict(_tradeHistoryAll)
        setIsLoadingTradeHistory(false)
    }

    const getStockShareHolderData = async () => {
        const dataReader = new DataReader('stock-shareholder-research',true);
        const queryData = {
            "selector": {
                "_id": {"$in": stockCodeList.map((item)=>('stockShareholderResearch:'+item))}
            },
            "fields": [
                "_id",
                "_rev",
                "data",
                "updateAt"
            ],
            "execution_stats": true
        }

        const _stockShareHolderData = await dataReader.post_request('/_find',queryData);
        //console.log(_stockShareHolderData)
        let _dict = {}
        _stockShareHolderData.docs?.map((item) => {
            _dict[item._id.split(':')[1]] = item
        })

        setStockShareHolderDict(_dict)
    }

    const getStockCompanyProfile = async() => {
        const dataReader = new DataReader('stock-company-profile',true);
        const queryData = {
            "selector": {
                "_id": {"$in": stockCodeList.map((item)=>('stockCompanyProfile:'+item))}
            },
            "fields": [
                "_id",
                "_rev",
                "data",
                "updateAt"
            ],
            "execution_stats": true
        }

        const _stockCompanyProfile = await dataReader.post_request('/_find',queryData);
        //console.log(_stockCompanyProfile)
        let _dict = {}
        _stockCompanyProfile.docs?.map((item) => {
            _dict[item._id.split(':')[1]] = item
        })

        setStockCompanyProfile(_dict)
    }


    const calculateTradeHistoryRates = () => {
        setTradeHistoryRateDict({})
        let _dict ={}
        //console.log('11111')
        for (let [key, value] of Object.entries(tradeHistoryEnabledDict)) {
                let _list = []
                value?.map((item) => {
                    switch (item.type) {
                        case "stock":
                            _list.push((stockBasicInfoDict[item.code]?.price/item.tradePrice-1)*100)
                            break;
                        case "bond":
                            _list.push((bondBasicInfoDict[item.code]?.bond_price/item.tradePrice-1)*100)
                            break;
                        case "fund":
                            _list.push((fundBasicInfoDict[item.code]?.net_value/item.tradePrice-1)*100)
                            break;
                    }
                    
            })
            _dict[key] = _list
        }
        //console.log(_dict)
        setTradeHistoryRateDict(_dict)
    }

    useEffect(() => {
        if(!userSettings) return;
        getMyListData();
        getTradeHistoryData();
    },[userSettings]);

    useEffect(() => {
        if(!stockCodeList.length){return}
        getStockInfoData();
        // getStockShareHolderData();
        // getStockCompanyProfile();

        const interval = setInterval(() => {
            getStockInfoData();
        }, MINUTE_MS);
        return () => clearInterval(interval);
    },[stockCodeList]);
    
    useEffect(() => {
        if(!bondCodeList.length){return}
        getBondBasicInfo();
        const interval = setInterval(() => {
            getBondBasicInfo();
        }, MINUTE_MS);
        
        return () => clearInterval(interval);
    },[bondCodeList]);

    useEffect(() => {
        if(!fundCodeList.length){return}
        getFundBasicInfo();
        const interval = setInterval(() => {
            getFundBasicInfo();
        }, MINUTE_MS);
        
        return () => clearInterval(interval);
    },[fundCodeList]);

    useEffect(() => {
        if(!isLoading && !isLoadingTradeHistory) { 
            calculateTradeHistoryRates();
            const interval = setInterval(() => {
                console.log('Logs every minute');
                calculateTradeHistoryRates();
            }, MINUTE_MS);
            
            return () => clearInterval(interval);
        }
        //calculateTradeHistoryRates();
    },[isLoading, isLoadingTradeHistory]);
    //console.log(myHoldListData)

    useEffect(() => {
        if(!Object.keys(stockBasicInfoDict).length && !Object.keys(bondBasicInfoDict).length){
            setIsLoading(true)
        }else{
            setIsLoading(false)
        }
    },[stockBasicInfoDict, bondBasicInfoDict]);

    const renderItem = ({ item }) => (
        <StockCardItem 
            id={item.id}
            strategyName={item.strategyName}
            code={item.code}
            name={item.name}
            financialCompanyName={item.financialCompanyName}
            holdNum={item.holdNum}
            costPerHold={item.costPerHold}
            type={item.type}
            createdAt={item.createdAt}
            stockBasicInfoDict={stockBasicInfoDict}
            bondBasicInfoDict={bondBasicInfoDict}
            fundBasicInfoDict={fundBasicInfoDict}
            tradeHistoryAllDict={tradeHistoryAllDict}
            tradeHistoryRateDict={tradeHistoryRateDict}
            stockShareHolderDict={stockShareHolderDict}
            stockCompanyProfile={stockCompanyProfile}
        />
    )

    return (
        // <Root theme='dark'>
            <View className="relative flex-1">
                
                {isLoading && (
                    <View className="absolute -top-4 right-10 z-100">
                        <ActivityIndicator size="large" color="#00ff00"/>
                    </View>
                    )}
                
                {/**
                 * <View>
                <Button
                    title={'toast notification'}
                    onPress={() =>
                        Toast.show({
                        type: ALERT_TYPE.SUCCESS,
                        title: 'Success',
                        textBody: 'Congrats! this is toast notification success',
                        })
                    }
                    />
                </View>
                 */}
                <View style={{ height:"100%",flex:1 }}>
                    {myHoldListData && (
                            <>
                                <HoldListHeaderButtons 
                                tradeHistoryRateDict={tradeHistoryRateDict}
                                myHoldListData={myHoldListData}
                                stockBasicInfoDict={stockBasicInfoDict}
                                bondBasicInfoDict={bondBasicInfoDict}
                                fundBasicInfoDict={fundBasicInfoDict}
                                />
                            <FlatList 
                            data={myHoldListData}
                            renderItem={renderItem}
                            keyExtractor={item => item.id}
                            showsVerticalScrollIndicator={false}
                        />
                        </>
                    )}
                </View>
            </View>
        // {/* </Root> */}
    )
}

export default StockCard