import { View, Text, Button, ScrollView, TouchableOpacity, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
//import { Table, TableWrapper, Row, Rows, Col } from 'react-native-table-component'
import { DataTable } from 'react-native-paper'
import { BackspaceIcon, PlusSmIcon } from 'react-native-heroicons/solid'
import { useNavigation } from '@react-navigation/native'
import { formatTimeStamp } from '../utils/CommonHelper'


const HoldListDetailScreen = ({route}) => {
    const { id, strategyName, code, name, financialCompanyName, holdNum, costPerHold, type, createdAt, stockBasicInfoDict, bondBasicInfoDict, tradeHistoryRateDict, tradeHistoryAllDict } = route.params;

    const [tableContent, setTableContent] = useState([])
    const navigation = useNavigation()

    const goBack = () => {
        if(Platform.OS === "web"){
            console.log('1111');
            window.history.back();
        }else{
            console.log("222");
            navigation.goBack;
        }
    }

    const formatTradeHistoryRate = (item) => {
        let res = []
        item?.map((i) => (
            res.push(parseFloat(i).toFixed(2) + '% ')
        ))
    
        return res
    }

    const calculateEstProfit = (type, code, tradePrice, tradeNum) => {
        let _estProfit = 0
        switch (type) {
            case "stock":
                _estProfit = (stockBasicInfoDict[code].price - tradePrice) * tradeNum
                break;
            case "bond":
                _estProfit = (bondBasicInfoDict[code].bond_price - tradePrice) * tradeNum
                break;
            case "fund":
                
                break;
        }
        return (_estProfit).toFixed(2)
    }

    const goBackByPlatform = () => {
        if (Platform.OS === "web") {
            return navigation.navigate('Home');
        }else{
            return navigation.goBack;
        }
    }

    useEffect(() => {
        if(!tradeHistoryAllDict[id]) return
        const formatTableContent = () => {
            const _tempList = []
            tradeHistoryAllDict[id]?.map((item) => (
                _tempList.push([
                    item.tradeType.toUpperCase(),
                    item.tradeNum,
                    item.tradePrice,
                    item.profit,
                    formatTimeStamp(item.tradeTime/1000)
                ])
            ))
            setTableContent(_tempList)
            console.log(_tempList)
        }

        formatTableContent()

    },[tradeHistoryAllDict, id])

    return (
        <SafeAreaView className="relative flex-1 mt-[100px]">
            <View className="absolute top-[50px] left-2 z-50">
                {/* BUG: navigation.goBack not working on web */}
                <BackspaceIcon size={30} color="#999" onPress={goBackByPlatform}/>
            </View>
            <View className="bg-white mt-2 rounded-t-xl p-3 px-4 pt-[40px] flex-1">
                <Text className="text-xl font-bold">[{strategyName}][{financialCompanyName}][{type.toUpperCase()}]{name}</Text>
                <Text>持仓: {holdNum} 均价: {costPerHold}</Text>
                <Text className="text-red-600">持仓涨跌: {formatTradeHistoryRate(tradeHistoryRateDict[id])}</Text>
                <View className="bg-blue-800 p-2 pl-4 mt-2 rounded-xl">
                    {type=='bond'&&(
                        <View className="space-x-3 flex-row">
                        <Text className="text-white">价格: {bondBasicInfoDict[code]?.bond_price}</Text>
                        <Text className="text-white">涨跌: {bondBasicInfoDict[code]?.bond_rate}%</Text>
                        </View>
                        
                    )}
                    {type=='stock'&&(
                        <View className="space-x-3 flex-row">
                        <Text className="text-white">价格: {stockBasicInfoDict[code]?.price}</Text>
                        <Text className="text-white">涨跌: {stockBasicInfoDict[code]?.stock_rate}%</Text>
                        </View>
                    )}
                </View>
                <DataTable>
                    <DataTable.Header>
                    <DataTable.Title>类型</DataTable.Title>
                    <DataTable.Title>数量</DataTable.Title>
                    <DataTable.Title>价格</DataTable.Title>
                    <DataTable.Title>EST利润</DataTable.Title>
                    <DataTable.Title>利润</DataTable.Title>
                    <DataTable.Title>时间</DataTable.Title>
                    <DataTable.Title>操作</DataTable.Title>
                    </DataTable.Header>
                    {tradeHistoryAllDict[id]?.map((item, index) => (
                        <DataTable.Row 
                        key={index}
                        className={`bg-white ${!item.enable&&"bg-slate-500"}`}>
                            <DataTable.Cell>{item.tradeType.toUpperCase()}</DataTable.Cell>
                            <DataTable.Cell>{item.tradeNum}</DataTable.Cell>
                            <DataTable.Cell>{item.tradePrice}</DataTable.Cell>
                            <DataTable.Cell>{calculateEstProfit(item.type, item.code, item.tradePrice, item.tradeNum)}</DataTable.Cell>
                            <DataTable.Cell>{item.profit}</DataTable.Cell>
                            <DataTable.Cell>{formatTimeStamp(item.tradeTime/1000)}</DataTable.Cell>
                            <DataTable.Cell>{item.enable?(<><Button title="SELL" className="ml-2" /></>):null}</DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
                <TouchableOpacity className="w-[100px] bg-blue-500 rounded-xl p-2 justify-center items-center mx-0 flex-row">
                    <PlusSmIcon size={20} color="#fff" />
                    <Text className="text-white text-xl">买入</Text>
                </TouchableOpacity>
            </View>
            
        </SafeAreaView>
    )
}

export default HoldListDetailScreen