import { useState } from "react";
import DataReader from "./DataReader"


const getStockBasicInfo = async (_codeList) => {
    const dataReader = new DataReader('stock-basic-info',true);
    const queryData = {
        "selector": {
            "stock_code": {"$in": _codeList}
        },
        "fields": [
            "_id",
            "_rev",
            "stock_code",
            "stock_secu_code",
            "stock_name",
            "price",
            "stock_rate",
            "stock_rate_amount",
            "stock_cjl",
            "stock_cje",
            "stock_zf",
            "high",
            "low",
            "open",
            "yesterday_close",
            "stock_hsl",
            "stock_syl",
            "stock_sjl",
            "stock_zsz",
            "stock_ltsz",
            "60days_rate",
            "year_start_rate",
            "5mins_rate",
            "update_at"
        ],
        "sort": [{"stock_rate": "asc"}],
        "execution_stats": true
    }

    const _stockBasicInfo = await dataReader.post_request('/_find',queryData);
    //console.log(_stockBasicInfo)
    return _stockBasicInfo
}

/**
 * * New method to get stock basic info, which is much faster then _find
 * @param {*} _codeList Stock Code List
 */
const getStockBasicInfoV2 = async (_codeList) => {
    const dataReader = new DataReader('stock-basic-info',true);
    const promisesArray = [];
    let resData = [];

    _codeList.forEach(_stockCode => {
        promisesArray.push(new Promise((resolve, reject) => {
            const res = dataReader.get_request('/stockBasicInfo%3A' + _stockCode);
            resolve(res);
            
        }));
    });

    await  Promise.all(promisesArray).then((_dataList) => {
        _dataList = _dataList.filter(item => item !== undefined)
        _dataList.sort((a,b) => a.stock_rate - b.stock_rate);
        //console.log(_dataList);
        resData = _dataList;
    });

    return resData;
}

const getBondBasicInfo = async (_codeList) => {
    const dataReader = new DataReader('bond-basic-info',true);
    const queryData = {
        "selector": {
            "bond_code": {"$in": _codeList}
        },
        "fields": [
            "_id",
            "_rev",
            "bond_code",
            "bond_name",
            "bond_price",
            "bond_rate",
            "redeem_price",
            "force_redeem_price",
            "dq_redeem_price",
            "stock_code",
            "stock_name",
            "stock_price",
            "stock_rate",
            "zgj",
            "zgjj",
            "zg_yjl",
            "czjj",
            "cz_yjl",
            "bond_apply_date",
            "bond_publish_date",
            "bond_covert_stock_date",
            "updateAt"
        ],
        "execution_stats": true
    }

    const _bondBasicInfo = await dataReader.post_request('/_find',queryData);
    return _bondBasicInfo
}

const getFundBasicInfo = async (_codeList) => {
    const dataReader = new DataReader('fund-basic-info',true);
    const queryData = {
        "selector": {
            "code": {"$in": _codeList}
        },
        "fields": [
            "_id",
            "_rev",
            "code",
            "fundName",
            "fundIntro",
            "fundCreateAt",
            "rate",
            "net_value",
            "fundCompany",
            "updateAt"
        ],
        "execution_stats": true
    }

    const _fundBasicInfo = await dataReader.post_request('/_find',queryData);
    return _fundBasicInfo
}

const getUserInfo = async(email, password) => {
    const dataReader = new DataReader('finance-tools',true);
    const queryData = {
        "selector": {
            "_id": {"$eq": "users:" + email },
            "email": {"$eq": email },
            "pass": {"$eq": password },

        },
        "fields": ["_id", "_rev", "email", "pass", "nickname","registered_at","login_at","doc_type","fund_chart_settings"],
        "execution_stats": true
    }
    const _userInfo = await dataReader.post_request('/_find',queryData);
    return _userInfo
}

export {
    getStockBasicInfo,
    getStockBasicInfoV2,
    getBondBasicInfo,
    getFundBasicInfo,
    getUserInfo
}